import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SectionHeader from '../SectionHeader';
import './styles.scss';

const Section = ({
  id,
  title,
  url,
  urlTitle,
  rightBlock,
  children,
  sectionAdditionalClasses,
  headlineType,
  noHeader,
  asLink,
  cypress
}) => {
  const sectionClasses = classNames('section', sectionAdditionalClasses);

  return (
    <section id={id} className={sectionClasses}>
      {noHeader || (
        <SectionHeader
          title={title}
          url={url}
          asLink={asLink}
          urlTitle={urlTitle}
          rightBlock={rightBlock}
          headlineType={headlineType}
          cypress={cypress}
        />
      )}
      {children}
    </section>
  );
};

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  asLink: PropTypes.string,
  noHeader: PropTypes.bool,
  rightBlock: PropTypes.object,
  urlTitle: PropTypes.string,
  children: PropTypes.node,
  sectionAdditionalClasses: PropTypes.string,
  headlineType: PropTypes.string,
  cypress: PropTypes.string
};

export default Section;
